<template>
  <div class="spell-group">
    <!-- 导航栏 -->
    <div class="SpellGroup-header" @touchmove.prevent>
      <van-nav-bar
        title="拼团列表"
        left-text=""
        right-text=""
        left-arrow
        @click-left="$goBack()"
      />
    </div>
    <div class="main">
      <div class="footerpell">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="warp"></div>
          <div
            class="footer-title"
            v-for="(item, index) in getSpellGrouplist.records"
            :key="index"
          >
            <p class="title-one">
              <span>
                <img :src="item.shopInfo.imgUrl" alt="" />
              </span>
              <span>
                {{ item.shopInfo.name }}
              </span>
            </p>
            <div class="title-two">
              <p class="title-two-pell">
                <img :src="item.goodsSpu.picUrls[0]" class="good-img" alt="" />
              </p>
              <p class="title-two-pall">
                <span class="two-pall-one van-multi-ellipsis--l2">{{
                  item.name
                }}</span>
                <span class="two-pall-two">
                  <span>￥{{ item.grouponPrice }}</span>
                  <del class="pall-one">￥{{ item.goodsSku.salesPrice }}</del>
                  <span class="pall-two"> 包邮 </span>
                </span>
                <span class="two-pall-trell">
                  <span>{{ item.grouponNum }}人团</span>
                  <span>已有{{ item.launchNum }}人参与</span>
                </span>
              </p>
            </div>
            <div class="Spell-pic" @click="qupintuantype(item.id)">
              前去拼团
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { getSpellGroup } from "@/api/Spellgroup/Spellgroup";
import { mapState } from "vuex";

export default {
  name: "SpellGroup",

  data() {
    return {
      current: 1,
      size: 10,
      finished: false,
      loading: false,
      getSpellGrouplist: [],
      id: "",
      total: 0,
    };
  },

  mounted() {
    //定义全局session
    this.$store.commit(
      "setSession",
      this.$route.query.session || "$2a$10$hevr3h9I6YO5TyE08hIKZ.jvEUrr2m4IZAYJ22rGVtPZzvulZxqS"
    );
    if (this.$route.query.session != null) {
      // this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.$route.query.session);
    }

    this.setRem();
    window.addEventListener("resize", this.setRem);
    window.addEventListener("orientationchange", this.setRem);
  },
  computed: {
    ...mapState(["session"]),
  },
  methods: {
    goNative() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    //上拉加载
    onLoad() {
      // console.log(111);
      this.getSpellGrouptype();
      this.current++;
    },
    getSpellGrouptype() {
      // sessionStorage.setItem('shopID')
      let params = { current: this.current, size: this.size };
      getSpellGroup(params).then((res) => {
        if (res.data.code == 0) {
          this.loading = false;
          this.getSpellGrouplist = res.data.data;
          this.total = res.data.data.total;
          // 数据全部加载完成
          if (this.getSpellGrouplist.records.length == this.total) {
            this.finished = true;
          }
        }
      });
    },
    qupintuantype(id) {
      this.$router.push({
        path: "/AstartAFight",
        query: {
          id: id,
          session: this.$store.state.session,
        },
      });
    },
  },
  beforeDestroy() {
    document.querySelector("html").style.fontSize = "16px";
  },
};
</script>
<style lang="less" scoped>
.spell-group {
  height: 100vh;
  overflow: hidden;
  background-color: #e64d42;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.main {
  height: 100%;
  box-sizing: border-box;
  padding-top: 0.666667rem;
}
.SpellGroup-header {
  position: fixed;
  // height: 0.586667rem;
  left: 0;
  right: 0;
  z-index: 999;
}
.nav-bar-title {
  font-weight: bold;
}
.Spell-pic {
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 8.293333rem;
  margin-top: 0.266667rem;
  font-size: 0.4rem;
  color: #fff;
  background: url("../../assets/qupintuan.png") no-repeat center;
  background-size: 100%;
}
.footerpell {
  background-image: url("../../assets/weitu.png");
  background-repeat: no-repeat;
  background-size: 100% 35%;
  padding-top: 0.586667rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #e64d42;
  font-size: 0.4rem;
  /deep/.van-list {
    flex: 1;
    overflow: auto;
  }
  .warp {
    padding-top: 40%;
    padding-bottom: 50px;
  }
  .footer-title {
    padding-bottom: 0.266667rem;
    border: 1px solid #fff;
    margin: 0 0.426667rem 0.4rem 0.426667rem;
    border-radius: 5px;
    background-color: #fff;
    .title-one {
      padding-top: 0.266667rem;
      padding-left: 0.333333rem;
      display: flex;
      align-items: center;
      :first-child {
        width: 0.666667rem;
        height: 0.666667rem;
      }
      :last-child {
        font-size: 0.4rem;
      }
      span {
        margin-right: 0.266667rem;
      }
      img {
        border-radius: 0.133333rem;
      }
    }

    .title-two {
      display: flex;
      margin: 0.393333rem 0 0 0.426667rem;
      padding-bottom: 0.266667rem;
      .title-two-pell {
        width: 2.426667rem;
        height: 100%;

        //  border: 1px solid red;
      }
      .title-two-pall {
        // border: 1px solid red;
        font-size: 0.4rem;
        display: flex;
        margin-left: 0.266667rem;
        flex-direction: column;
        justify-content: space-around;

        .two-pall-one {
          color: #333333;
        }
        .two-pall-two {
          display: flex;
          align-items: center;
          :first-child {
            color: #a80000;
          }
          .pall-one {
            padding: 0 10px 0 10px;
            color: #666;
            font-size: 0.4rem;
          }
          .pall-two {
            display: block;
            background-color: #ff5200;
            color: #fff;
            font-size: 0.32rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.066667rem;
          }
        }
        .two-pall-trell {
          //  border: 1px solid red;
          display: flex;
          align-items: center;
          :first-child {
            display: block;
            color: #ff5200;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            border: 1px solid red;
            font-size: 0.32rem;
          }
          :last-child {
            font-size: 0.32rem;
            margin-left: 0.266667rem;
            color: #333333;
          }
        }
      }
    }
  }
}
.vant-list {
  font-size: 0.32rem !important;
}
.good-img {
  border-radius: 0.266667rem;
  height: 2.133333rem;
  width: 2.133333rem;
}
</style>